import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { ImageDataLike } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import Learn from '@clearscore/website.learn-article';

import Layout from '../components/layout';

const LearnTemplate = ({
    data,
    pageContext: { isWebView = false },
    location,
}: PageProps<Queries.Query, { isWebView: boolean }>): React.ReactElement => {
    const { learnArticle } = data;
    const {
        language,
        props,
        author,
        tags,
        hero,
        meta,
        publishedDate,
        formattedPublishDate,
        childMdx,
        authorImageNode,
        heroImageNode,
    } = learnArticle;
    const { body, timeToRead, wordCount, tableOfContents } = childMdx;

    const authorImage = getImage(authorImageNode as unknown as ImageDataLike);
    const heroImage = getImage(heroImageNode as unknown as ImageDataLike);
    const authorProps = {
        ...author,
        ...author.language,
        image: authorImage,
        publishedDate,
        formattedPublishDate,
    };
    const heroProps = {
        ...hero,
        image: heroImage,
    };

    return (
        <Layout
            meta={{ ...meta, image: (heroImage as { publicURL?: string })?.publicURL || '' }}
            location={location.pathname}
            schemaMarkup={{
                '@context': 'https://schema.org',
                '@type': 'Article',
                mainEntityOfPage: {
                    '@type': 'WebPage',
                    '@id': location.href ?? '',
                },
                articleSection: learnArticle.props.primaryCategory,
                headline: meta.title,
                description: meta.description,
                timeRequired: `PT${timeToRead}M`,
                image: heroImage?.images?.fallback?.src,
                author: {
                    '@type': 'Person',
                    name: author.name,
                    jobTitle: author?.language?.title,
                    image: authorImage?.images?.fallback?.src,
                },
                publisher: {
                    '@type': 'Organization',
                    name: 'ClearScore',
                    logo: {
                        '@type': 'ImageObject',
                        url: 'https://cdn-fs.clearscore.com/icons/@clearscore/rainbow.icons.clearscore/0.2.3/clearscore@3x.png',
                        width: '96',
                        height: '96',
                    },
                },
                datePublished: new Date(Number(publishedDate)).toISOString(),
                // TODO: We need to add something like this to CMS
                // dateModified: lastModified,

                // NOTE: notice, we don't have a body here, this is because
                // our body comes as MDX so we can't really display it as is.
                // To work around that and not to render it to string we still
                // have small bit of microdata format on the mdx renderer
                // where body is actually rendered.
            }}
        >
            {({ commonModules }): React.ReactElement => (
                <Learn
                    isWebView={isWebView}
                    language={{
                        ...language,
                        content: body,
                    }}
                    pageConfig={{
                        ...props,
                        timeToRead,
                        wordCount: wordCount.words,
                        tableOfContents,
                        // @ts-expect-error fix after migration to TS
                        tags,
                    }}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    appStoreConfig={commonModules.app_store}
                    // @ts-expect-error fix after migration to TS
                    author={authorProps}
                    // @ts-expect-error fix after migration to TS
                    hero={heroProps}
                />
            )}
        </Layout>
    );
};

// querying here as gatsby doesnt support variables in static queries
// look in learn asset folder for any images create at build step found in this pages context
export const query = graphql`
    query LearnArticle($slug: String, $locale: String) {
        learnArticle(slug: { eq: $slug }, locale: { eq: $locale }) {
            childMdx {
                timeToRead
                wordCount {
                    words
                }
                tableOfContents(maxDepth: 2)
                body
            }
            meta {
                description
                title
                url
            }
            publishedDate
            formattedPublishDate
            tags {
                name
                slug
            }
            language {
                title
                heading
                description
                ctaTitle
                ctaBlurb
                ctaButtonCopy
                keyHighlights
                keyHighlightsTitle
            }
            author {
                name
                language {
                    title
                    bio
                }
            }
            hero {
                name
                category
                language {
                    copyright
                    altText
                }
            }
            props {
                primaryCategory
                secondaryCategory
                primaryCategoryUrl
                displayDescription
                wordCount
                displayToc
                ctaUrl
            }
            heroImageNode {
                childImageSharp {
                    gatsbyImageData(quality: 90, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                }
            }
            authorImageNode {
                childImageSharp {
                    gatsbyImageData(quality: 90, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                }
            }
        }
    }
`;

export default LearnTemplate;
