import type { ReactElement } from 'react';
import React, { Fragment } from 'react';
import Text from '@clearscore/ui.rainbow.text';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

interface ITableOfContents {
    tableOfContents: {
        items?: Array<{
            title: string;
            url: string;
        }>;
    };
}

const TableOfContents = ({ tableOfContents = {} }: ITableOfContents): ReactElement | null => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    return tableOfContents.items ? (
        <div className={styles.container}>
            <Fragment>
                <div className={styles.title}>
                    <Text.H4 tag={Text.tags.H2}>{t('contentsTitle')}</Text.H4>
                </div>
                <ul>
                    {tableOfContents.items.map((item) => (
                        <li key={item.title} className={styles.menuItem}>
                            <Text.Link isSimpleLink href={item.url.replace(/(-)/g, '')}>
                                {item.title}
                            </Text.Link>
                        </li>
                    ))}
                </ul>
            </Fragment>
        </div>
    ) : null;
};

export default TableOfContents;
