import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import List from '@clearscore/ui.rainbow.list';

import styles from './highlights.module.css';
import Icon from './lightbulb.svg';

interface IItemProps {
    children: ReactNode;
}

const Item = ({ children }: IItemProps): ReactElement => {
    const stripOutNumbersRegEx = /([0-9]. )|([*]. )$/g;
    const highlight =
        (typeof children === 'string' ? children?.split(stripOutNumbersRegEx)?.[3] : children) ?? children;
    return <List.Item>{highlight}</List.Item>;
};

const validateChildren = (children: ReactNode): boolean => {
    const valid = React.Children.map(children, (child) => React.isValidElement(child) && !!child?.props.children);
    return Array.isArray(valid) && !!valid.filter(Boolean).length;
};

interface IArticleHighlightsProps {
    heading: string;
    children: ReactNode;
}

const ArticleHighlights = ({
    heading = 'Things To Highlight',
    children = [],
}: IArticleHighlightsProps): ReactElement | null => {
    const isValid = validateChildren(children);
    if (!isValid) return null;
    return (
        <div data-qa="article-highlights" className={styles.copy}>
            <List isNumbered spacing={List.Spacing.LARGE} icon={Icon} heading={heading}>
                {children}
            </List>
        </div>
    );
};

ArticleHighlights.Item = Item;

export default ArticleHighlights;
