import type { ReactElement } from 'react';
import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import Button from '@clearscore/ui.rainbow.button';
import cx from 'classnames';

import styles from './styles.module.css';

interface IArticlePrimaryCtaProps {
    title: string;
    blurb: string;
    cta: string;
    url: string;
    onClick?: React.MouseEventHandler;
}

const ArticlePrimaryCta = ({ title, blurb, cta, url, onClick }: IArticlePrimaryCtaProps): ReactElement => (
    <div data-qa="article-primary-cta" className={styles.container}>
        <div className={cx(styles.spacingMedium, styles.title)}>
            <Text.H4 tag={Text.tags.H2}>{title}</Text.H4>
        </div>
        <div className={styles.spacingMedium}>
            <Text.Body2>{blurb}</Text.Body2>
        </div>
        <Button href={url} onClick={onClick} isNarrow>
            {cta}
        </Button>
    </div>
);

export default ArticlePrimaryCta;
