import i18next from 'i18next';
import { EN_GB } from '@clearscore-group/lib.config.i18n';

import Learn from './learn';
import { PAGE_NAMESPACE } from './lib/constants';
import copyEnGb from './copy/pages/learn-article/en-gb/copy.json';

i18next.addResourceBundle(EN_GB, PAGE_NAMESPACE, copyEnGb);

export default Learn;
