import useWesbiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';
import type React from 'react';

export type TargettedEvent = React.MouseEvent<Element, MouseEvent> & { currentTarget: { target?: HTMLElement } };

const CTA_EVENT = 'fe_learn_article_cta_clicked';
const LINK_EVENT = 'fe_learn_article_link_clicked';

interface ITrackCtaClickArgs {
    e: TargettedEvent;
    description?: string;
    copy: string;
    title?: string;
    ctaType: string;
    url: string;
}

interface ITrackLinkClickArgs {
    e: TargettedEvent;
    copy: string;
    url: string;
}

interface IWebsiteTracking {
    trackCtaClick: (args: ITrackCtaClickArgs) => void;
    trackLinkClick: (args: ITrackLinkClickArgs) => void;
}

export default (): IWebsiteTracking => {
    const track = useWesbiteTracking();

    return {
        trackCtaClick({ e, description, copy, title, ctaType, url }: ITrackCtaClickArgs): void {
            const newTab = !!e.currentTarget?.target;

            if (e && !newTab) e.preventDefault();

            track({
                name: CTA_EVENT,
                props: {
                    e: CTA_EVENT,
                    title,
                    description,
                    copy,
                    cta_type: ctaType,
                },
                onComplete: () => {
                    if (!newTab) window.location.assign(url);
                },
            });
        },
        trackLinkClick({ e, copy, url }: ITrackLinkClickArgs): void {
            const newTab = !!e.currentTarget?.target;

            if (e && !newTab) e.preventDefault();

            track({
                name: LINK_EVENT,
                props: {
                    copy,
                    e: LINK_EVENT,
                },
                onComplete: () => {
                    if (!newTab) window.location.assign(url);
                },
            });
        },
    };
};
