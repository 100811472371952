import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import IconClock from './clock--timer.svg';
import styles from './author-bio.module.css';
import spacing from '../../spacing.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

interface IAuthorImage {
    isLarge?: boolean;
    image: IGatsbyImageData;
    name: string;
}

const AuthorImage = ({ isLarge = false, image, name }: IAuthorImage): ReactElement => (
    <div className={cx(styles.authorImage, { [styles.large]: isLarge })}>
        {image && <GatsbyImage className={styles.image} image={image} alt={`${name} Image`} title={`${name} Image`} />}
    </div>
);

interface IGetDisplayDateArgs {
    timeFromPublished: number;
    formattedPublishDate: string;
    yesterday: string;
    today: string;
}

const getDisplayDate = ({ timeFromPublished, formattedPublishDate, yesterday, today }: IGetDisplayDateArgs): string => {
    if (timeFromPublished <= 24) {
        return today;
    }
    if (timeFromPublished > 24 && timeFromPublished <= 48) {
        return yesterday;
    }
    return formattedPublishDate;
};

interface IAuthorSummaryProps {
    name: string;
    publishedDate: string;
    formattedPublishDate: string;
    timeToRead: number;
}

const AuthorSummary = ({
    name,
    publishedDate,
    formattedPublishDate,
    timeToRead,
}: IAuthorSummaryProps): ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const nowTimeStamp = Date.now();
    const timeFromPublished = (nowTimeStamp - parseInt(publishedDate, 10)) / 1000 / 60 / 60;
    const displayDate = getDisplayDate({
        timeFromPublished,
        formattedPublishDate,
        today: t('today'),
        yesterday: t('yesterday'),
    });
    return (
        <div className={styles.authorBio} data-qa="authorSummary">
            <span className={styles.alignCenter}>
                <Text type={Text.types.TINY}>
                    <span>{displayDate}</span>
                    <span className={styles.dotDivider}>{name}</span>
                    <span data-qa="article-timeToRead" className={styles.dotDivider}>
                        <IconClock className={styles.icon} width={16} height={16} /> {t('minRead', { timeToRead })}
                    </span>
                </Text>
            </span>
        </div>
    );
};

export interface IAuthorProps {
    title: string;
    name: string;
    bio: string;
    image: IGatsbyImageData;
    isArticleFooter?: boolean;
}

const Author = ({ title, bio, name, image, isArticleFooter = false }: IAuthorProps): ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    return (
        <div className={spacing.tiny}>
            <div
                className={cx(styles.authorBio, spacing.medium, {
                    [styles.isArticleFooter]: isArticleFooter,
                })}
            >
                <AuthorImage isLarge image={image} name={name} />
                <div>
                    <div className={spacing.tiny}>
                        <Text.H3 tag={Text.tags.H2}>{t('writtenBy', { author: name })}</Text.H3>
                    </div>
                    <div className={spacing.tiny}>
                        <Text.H4 tag={Text.tags.H2}>{title}</Text.H4>
                    </div>
                </div>
            </div>
            <Text.Body1>{bio}</Text.Body1>
        </div>
    );
};

Author.Summary = AuthorSummary;

export default Author;
