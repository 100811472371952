import type { RefObject, ReactElement } from 'react';
import React, { useState, useEffect } from 'react';

import styles from './styles.module.css';

interface IScrollProgressBarProps {
    target: RefObject<HTMLElement>;
}

const ScrollProgressBar = ({ target }: IScrollProgressBarProps): ReactElement => {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const scrollListener = (): void => {
            if (!target.current) {
                return;
            }
            const quaterViewPort = 1.25;
            const element = target.current;
            const totalHeight = element.clientHeight - window.innerHeight / quaterViewPort;
            const windowScrollTop =
                window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

            if (windowScrollTop === 0) {
                setProgress(0);
            }

            if (windowScrollTop > totalHeight) {
                setProgress(100);
            }

            setProgress(Number(((windowScrollTop / totalHeight) * 100).toFixed(1)));
        };

        window.addEventListener('scroll', scrollListener);

        return (): void => {
            window.removeEventListener('scroll', scrollListener);
        };
    });

    return <div data-qa="progress-bar" className={styles.progressBar} style={{ width: `${progress}%` }} />;
};

export default ScrollProgressBar;
